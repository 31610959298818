/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    ol: "ol",
    li: "li",
    p: "p",
    ul: "ul",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Copy the text below"), "\n", React.createElement(_components.li, null, "Paste it into ", React.createElement("a", {
    href: "https://www.hemingwayapp.com",
    target: "_blank"
  }, "hemingwayapp.com")), "\n", React.createElement(_components.li, null, "Edit to improve the readability score"), "\n"), "\n", React.createElement(_components.h3, null, "Text to be revised:"), "\n", React.createElement(_components.p, null, "This assignment is to make this document more readable.\nTo do so, you might:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Break up long sentences"), "\n", React.createElement(_components.li, null, "Remove long words"), "\n", React.createElement(_components.li, null, "Turn long sentences into lists"), "\n"), "\n", React.createElement(_components.p, null, "Here are a few example sentences:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In a deployment with multiple financial institutions, the 'sub' field must be unique to each user in the institution"), "\n", React.createElement(_components.li, null, "This approach enables you to more easily integrate with other data networks"), "\n", React.createElement(_components.li, null, "Create an account on the ", React.createElement(_components.a, {
    href: "#"
  }, "dashboard"), ". Navigate to Team settings. Click on Keys. Copy the client_id and send it to your integration support contact."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
